import React from 'react';
import './normalize.css';
import './bootstrap.min.css';
import './unicons.css';
import './App.css';
import logo from './assets/images/logo.png';
import chocoPiedras1 from './assets/images/choco-piedras-1.png';
import miniGelatinas1 from './assets/images/minigelatinas1.JPG';
import chocoLogo from './assets/images/chocologo1.png';
import chocoPiedras3 from './assets/images/chocopiedras3.JPG';
import miniGelatinas2 from './assets/images/minigelatinas2.jpg';
import paquete1 from './assets/images/paquete1.JPG';
import paquete2 from './assets/images/paquete2.JPG';
import paquete3 from './assets/images/paquete3.jpeg';
import centralAnastos from './assets/images/logo-central-de-abasto.JPG';
import chilimBalan from './assets/images/logo-chilim-balam.JPG';
import johe from './assets/images/logo-johe.JPG';
import mercadoMerced from './assets/images/logo-mercado-merced.JPG';
import cospor from './assets/images/logo-cospor-distribuciones.JPG';
import docChocopiedras from './assets/docs/chocopiedras-confiteria-lara-composiccion-nutricional.pdf';
import docMiniGelatinas from './assets/docs/minigelatinas-confiteria-lara-composicion-nutricional.pdf';

function App() {
    return <div className="bg-site">
        <nav className="navbar navbar-expand-sm navbar-light bg-menu">
            <div className="container">
                <a className="navbar-brand" href="/">Confilara</a>

                <button className="navbar-toggler" type="button" data-toggle="collapse" data-target="#navbarNav"
                        aria-controls="navbarNav" aria-expanded="false"
                        aria-label="Toggle navigation">
                    <span className="navbar-toggler-icon"></span>
                    <span className="navbar-toggler-icon"></span>
                    <span className="navbar-toggler-icon"></span>
                </button>

                <div className="collapse navbar-collapse" id="navbarNav">
                    <ul className="navbar-nav mx-auto">
                        <li className="nav-item">
                            <a href="#about" className="nav-link"><span
                                data-hover="¿Quiénes somos?">¿Quiénes somos?</span></a>
                        </li>
                        <li className="nav-item">
                            <a href="#project" className="nav-link"><span data-hover="Nuestros Productos">Nuestros Productos</span></a>
                        </li>
                        <li className="nav-item">
                            <a href="#resume" className="nav-link"><span data-hover="Centros de distribución">Centros de distribución</span></a>
                        </li>
                        <li className="nav-item">
                            <a href="#contact" className="nav-link"><span data-hover="Contacto">Contacto</span></a>
                        </li>
                    </ul>
                </div>
            </div>
        </nav>
        <section className="about full-screen d-lg-flex justify-content-center align-items-center" id="about">
            <div className="container">
                <div className="row">

                    <div className="col-lg-7 col-md-12 col-12 d-flex align-items-center">
                        <div className="about-text">

                            <h1 className="animated animated-text">
                                <span className="mr-2">Bienvenidos a </span>
                                <div className="animated-info">
                                    <span className="animated-item">Confitería</span>
                                    <span className="animated-item">Lara</span>
                                </div>
                            </h1>
                            <br/>
                            <small className="small-text">¿Quiénes somos?</small>
                            <p>Confitería Lara es una empresa dedicada a la importación y distribución de productos de
                                confitería, fundada en 2004. </p>
                            <small className="small-text">Misión</small>
                            <p>Ser una empresa comprometida en lograr la correcta comercialización y distribución de
                                nuestros productos, satisfaciendo las necesidades de nuestros clientes y
                                consumidores.</p>
                        </div>
                    </div>

                    <div className="col-lg-5 col-md-12 col-12">
                        <div className="about-image svg">
                            <img src={logo} className="img-fluid" alt="Logo"/>
                        </div>
                        <br/>
                        <small className="small-text">Objetivo</small>
                        <p>Proporcionar a nuestros clientes productos de calidad a través de un servicio que satisfaga
                            sus necesidades, contribuyendo siempre en el desarrollo de nuestros proveedores,
                            distribuidores y clientes.</p>
                        <small className="small-text">Filosofía</small>
                        <p>Orientar siempre nuestros esfuerzos hacia un trabajo en equipo apoyando siempre a nuestro
                            personal y las necesidades que requieran.</p>
                    </div>

                </div>
            </div>
        </section>

        <section className="project py-5" id="project">
            <div className="container">

                <div className="row">
                    <div className="col-lg-11 text-center mx-auto col-12">

                        <div className="col-lg-10 mx-auto">
                            <h3>A lo largo de nuestra historia hemos representado a  marcas como, Chocolates Garoto, Embaré Industrias Alimenticias, Guittard Chocolates, Pobeda Chocolates y Mavalerio. </h3>
                        </div>
                        <br/>
                        <p className="text-center">Actualmente Manejamos bajo el concepto de marcas propias las líneas
                            de: </p>
                        <div className="row product">
                            <div className="col-lg-12 col-12 text-center">
                                <h4 className="mb-6">CHOCO PIEDRA</h4>
                                <div className="custom-btn-group mt-6">
                                    <a href={docChocopiedras} className="btn mr-lg-2 custom-btn" download>
                                        <i className='uil uil-file-alt'/>
                                        Ficha nutricional
                                    </a>
                                </div>
                            </div>
                            <div className="col-xl-6 col-lg-6 col-md-6 col-sm-6 col-12 text-center">
                                <div className="card card-medium">
                                    <div className="card-center">
                                        <img src={chocoLogo} className="img-fluid" alt="chocoLogo"/>
                                    </div>
                                </div>
                            </div>
                            <div className="col-xl-6 col-lg-6 col-md-6 col-sm-6 col-12 text-center">
                                <div className="card card-medium">
                                    <div className="card-center">
                                        <img src={chocoPiedras3} className="img-fluid" alt="chocoPiedras3"/>
                                    </div>
                                </div>
                            </div>
                            <div className="col-xl-6 col-lg-6 col-md-6 col-sm-6 col-12 text-center">
                                <div className="card card-medium">
                                    <div className="card-center">
                                        <img src={chocoPiedras1} className="img-fluid" alt="chocoPiedras1"/>
                                    </div>
                                </div>
                            </div>
                            <div className="col-xl-6 col-lg-6 col-md-6 col-sm-6 col-12 text-center">
                                <div className="card card-medium">
                                    <div className="card-center">
                                        <img src={paquete1} className="img-fluid" alt="paquete1"/>
                                    </div>
                                </div>
                            </div>
                            <div className="col-xl-6 col-lg-6 col-md-6 col-sm-6 col-12 text-center">
                                <div className="card card-medium">
                                    <div className="card-center">
                                        <img src={paquete2} className="img-fluid" alt="paquete2"/>
                                    </div>
                                </div>
                            </div>
                            <div className="col-xl-6 col-lg-6 col-md-6 col-sm-6 col-12 text-center">
                                <div className="card card-medium">
                                    <div className="card-center">
                                        <img src={paquete3} className="img-fluid" alt="paquete3"/>
                                    </div>
                                </div>
                            </div>
                        </div>

                        <div className="row product">
                            <div className="col-lg-12 col-12 text-center">
                                <h4 className="mb-6 mobile-mt-2">MINI GELATINA LARA</h4>
                                <div className="custom-btn-group mt-6">
                                    <a href={docMiniGelatinas} className="btn custom-btn custom-btn-bg custom-btn-link"
                                       download>
                                        <i className='uil uil-file-alt'/>
                                        Ficha nutricional
                                    </a>
                                </div>
                            </div>
                            <br/>
                            <div className="col-xl-6 col-lg-6 col-md-6 col-sm-6 col-12 text-center">
                                <div className="card card-medium">
                                    <div className="card-center">
                                        <img src={miniGelatinas2} className="img-fluid" alt="miniGelatinas2"/>
                                    </div>
                                </div>
                            </div>
                            <div className="col-xl-6 col-lg-6 col-md-6 col-sm-6 col-12 text-center">
                                <div className="card card-medium">
                                    <div className="card-center">
                                        <img src={miniGelatinas1} className="img-fluid" alt="miniGelatinas1"/>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </section>

        <section className="project py-5" id="resume">
            <div className="container">
                <div className="row">
                    <div className="col-lg-11 text-center mx-auto col-12">
                        <div className="col-lg-8 mx-auto">
                            <h2>Centros de distribución.</h2>
                        </div>
                        <p>Todos nuestros productos puedes encontrarlos en los siguientes centros de distribución</p>
                        <div className="col-lg-11 mx-auto">
                            <div className="cards-section">
                                <div className="card card-small card-white">
                                    <div className="card-center">
                                        <img src={centralAnastos} alt="centralAnastos" className="card-image"/>
                                    </div>
                                </div>
                                <div className="card card-small card-white">
                                    <div className="card-center">
                                        <img src={chilimBalan} alt="chilimBalan" className="card-image"/>
                                    </div>
                                </div>
                                <div className="card card-small card-white">
                                    <div className="card-center">
                                        <img src={mercadoMerced} alt="mercadoMerced" className="card-image"/>
                                    </div>
                                </div>
                                <div className="card card-small card-white">
                                    <div className="card-center">
                                        <img src={cospor} alt="cospor" className="card-image"/>
                                    </div>
                                </div>
                                <div className="card card-small card-white">
                                    <div className="card-center">
                                        <img src={johe} alt="johe" className="card-image"/>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </section>

        <section className="contact py-5" id="contact">
            <div className="container">
                <div className="row">
                    <div className="col-lg-11 text-center mx-auto col-12">
                        <div className="col-lg-8 mx-auto">
                            <h2>Contacto</h2>
                        </div>
                        <div className="contact-info-item">
                            <h3 className="mb-3">Para mayor información contáctanos por: </h3>
                            <p><a href="mailto:eduardo@confilara.com.mx">Correo electrónico:
                                eduardo@confilara.com.mx</a></p>
                            <p className="footer-text mb-0">Teléfono de contacto: 58-90-56-40</p>
                            <p className="footer-text mb-0">Nuestras redes sociales: <a
                                href="https://www.facebook.com/Confiteria-Lara-SA-De-CV-154832361238727/"
                                target="_blank"> facebook Confiteria Lara, S.A. De C.V.</a></p>
                            <p className="footer-text mb-0">Dirección: Azahares #46, Col.
                                Jardines del Alba, Cuautitlán Izcalli, Edo. de Mèx., CP 54750. </p>
                        </div>
                    </div>
                </div>
            </div>
        </section>
    </div>;
}

export default App;
